import React from "react";
import {ReactCookieProps, useCookies} from "react-cookie";
import {AgeGateCookieEnum, ThemeModeEnum} from "../../utils/enums";

export interface RootState {
    showAgeGate: boolean;
    // Differentiation between `selectedTheme` and `effectiveTheme` so we can store `auto` in localStorage and
    // set dark/light on each load.  However, the "effectiveTheme" the one which determines the _actual_ colors used.
    theme: {
        selectedTheme: ThemeModeEnum,
        effectiveTheme: Exclude<ThemeModeEnum, 'auto'>;
    }
    updateTheme: (darkMode: ThemeModeEnum) => void;
    acceptAgeGate: (rememberMe: boolean, allowAll: boolean) => void;
}

export const RootContext = React.createContext<RootState>({
    showAgeGate: true,
    theme: {
        selectedTheme: ThemeModeEnum.auto,
        effectiveTheme: ThemeModeEnum.light,
    },
    updateTheme: (_) => {
    },
    acceptAgeGate: (_) => {
    },
});
export default RootContext;


/**
 * Initializes the Google tag by checking for `utm_X` URL parameters.  If any are found,
 * they are set globally in the tag.
 *
 * @param status {'granted' | 'denied' | null} If `null`, will not fire `setTag`.  Otherwise, passes
 * value to `setTag` to actively set user's consent value for cookie-related storage.
 *
 * @returns {void}
 */
function initGTag(status: 'granted' | 'denied' | null): void {
    if (!!status) setTag(status);
    if ('gtag' in window && typeof window.gtag === 'function') {

        const params = new URLSearchParams(window.location.search);
        const campaignValues: Record<string, string> = {};

        // Ref: https://support.google.com/analytics/answer/10917952?hl=en
        const campaign_id = params.get('utm_id');
        const campaign_name = params.get('utm_campaign');
        const campaign_medium = params.get('utm_medium');
        const campaign_source = params.get('utm_source');

        if (!!campaign_id) campaignValues[campaign_id] = campaign_id;
        if (!!campaign_name) campaignValues[campaign_name] = campaign_name;
        if (!!campaign_medium && !!campaign_source) {
            // Ref: https://support.google.com/analytics/answer/11259997?hl=en
            // "medium" and "source" are required.  Without these, nothing can be set.
            // There's a URL builder here: https://ga-dev-tools.google/ga4/campaign-url-builder/
            campaignValues[campaign_medium] = campaign_medium;
            campaignValues[campaign_source] = campaign_source;
            window.gtag('set', campaignValues);
        }
    }
}

/**
 * D.R.Y. wrapper to return the "actual" theme in use by the client, given the input `selectedTheme`.
 * This will output _only_ `light/dark`, so `auto` does not need to be handled in each and every case.
 * This is used entirely within this context, and for the purpose of setting `theme.effectiveTheme` state.
 * */
function getTheme(selectedTheme: string | null): RootState['theme']['effectiveTheme'] {
    if (selectedTheme === ThemeModeEnum.dark || selectedTheme == ThemeModeEnum.light) return selectedTheme;
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? ThemeModeEnum.dark : ThemeModeEnum.light;
}

/**
 * Wrapper for the Google Tag interface to grant/deny permission to use storage for Google Analytics tracking.
 *
 * @param status {('granted' | 'denied')} - Passed to `gtag`'s consent setting for all storage options.
 * @returns {void}
 * */
function setTag(status: 'granted' | 'denied'): void {
    // Check that `gtag` exists on the window.  If so, update the consent status with
    // either `granted` or `denied` as appropriate.
    if ('gtag' in window && typeof window.gtag === 'function') {
        window.gtag('consent', 'update', {
            ad_storage: status,
            analytics_storage: status,
        });
    }
}

export const RootProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [cookies, setCookies] = useCookies([AgeGateCookieEnum.CookieName]);
    const [showAgeGate, setShowAgeGate] = React.useState(
        ![AgeGateCookieEnum.ConsentAllValue, AgeGateCookieEnum.ConsentReqValue].includes(cookies.ageCheck)
    );

    const [theme, setTheme] = React.useState((): RootState['theme'] => {
        // Generate in a callback to ensure `localStorage` is available (i.e. this is running on the client).
        // Get the value from localStorage and ensure it's of the ThemeModeEnum type (and not just _any_ string), or default to `auto`.
        // Transform that `selectedTheme` into its related `effectiveTheme` and store.
        const localDM = localStorage.getItem('data-bs-theme');
        const selectedTheme = ([ThemeModeEnum.auto, ThemeModeEnum.dark, ThemeModeEnum.light].includes(localDM as ThemeModeEnum))
            ? localDM as ThemeModeEnum
            : ThemeModeEnum.auto;

        return {
            selectedTheme,
            effectiveTheme: getTheme(selectedTheme)
        }
    });


    /**
     * Callback for the `AgeGateComponent` which handles setting the appropriate cookie values.  Gets passed through
     * the RootContext.Consumer into the <AgeGateComponent />.
     *
     * @param rememberMe {boolean} - If `false`, the age-gate cookie will be created as a Session cookie (i.e. expires
     * on browser close).  If `true`, then the cookie will have a `maxAge` defined by the `AgeGateCookieEnum.CookieDuration`
     * value.
     *
     * @param allowAll {boolean} - If `true`, will consider cookie consent as "allow all" and thus will grant Google
     * Tag storage access.  If `false`, the user consents to required cookies _only_, and thus will set GTag storage
     * access to `denied.`
     * */
    function acceptAgeGate(rememberMe: boolean, allowAll: boolean): void {
        // Init without `expires` or `maxAge`, so the default cookies are session-based.
        // If the user has selected `rememberMe`, then set a `maxAge`.
        const baseOptions: ReactCookieProps['defaultSetOptions'] = {
            domain: window.location.hostname.split('.').filter(s => s !== 'www').join('.'),
            path: '/',
            sameSite: 'lax',
        }

        if (rememberMe) baseOptions.maxAge = AgeGateCookieEnum.CookieDuration as NonNullable<ReactCookieProps['defaultSetOptions']>['maxAge'];
        setCookies(AgeGateCookieEnum.CookieName, allowAll ? AgeGateCookieEnum.ConsentAllValue : AgeGateCookieEnum.ConsentReqValue, baseOptions);

        // Update the gTag based on the response.
        setTag(allowAll ? 'granted' : 'denied');

        setShowAgeGate(false);
    }

    function updateTheme(newTheme: ThemeModeEnum) {
        setTheme({
            selectedTheme: newTheme,
            effectiveTheme: getTheme(newTheme),
        })
    }

    const providerState: RootState = {
        showAgeGate,
        theme,
        updateTheme: updateTheme.bind(this),
        acceptAgeGate: acceptAgeGate.bind(this)
    };

    // Run this in `useEffect` to ensure it's on the client site (and thus `window` exists).
    React.useEffect(() => {
        const initValue = [AgeGateCookieEnum.ConsentAllValue, AgeGateCookieEnum.ConsentReqValue].includes(cookies.ageCheck)
            ? cookies.ageCheck === AgeGateCookieEnum.ConsentAllValue ? 'granted' : "denied"
            : null; // Prevents setTag from being fired if the AgeGateCookie isn't set yet.

        initGTag(initValue);
    }, []);

    // Watcher for changes in the `theme` state.
    React.useEffect(() => {
        // Store the updated `selectedTheme` in localStorage.
        localStorage.setItem('data-bs-theme', theme.selectedTheme)
        // and set the `effectiveTheme` in the document; Bootstrap's CSS responds to automatically to this data attr
        document.documentElement.setAttribute('data-bs-theme', theme.effectiveTheme);
        // Splitting `selected` and `effective` allows us to include `auto` in localStorage, but directly apply only dark/light to the _actual_ page.
    }, [theme]);

    return <RootContext.Provider value={providerState}>
        {props.children}
    </RootContext.Provider>
}
